import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81WTZOiMBC9+ytStRc5xBLEj8HL/pOpkLSSNSZUCMK45X/fAI5LEFRqZqv2mu5+/br7dZLZu38IxEEG8yhRJ9Do9wQhqoTSEToRPcX43d8U61VcBAtva20GSoMZUKWJ4UpGKJcMtOAStpPLZPYJ50dSmWm04zozmCZcMK+GjpW27ljAzh43aYwmMkuJBmkciKAOqK28SUWEQMF8fswqImfMbeYyQr4TtWgqyHVWYaeKSwO68o8JPey1snRxb31+6TlAYT9Q26PVsrHoyzpqp6TBBfB9YroBi8QNWA3V1T+ssBP9vdNdj6n85FLZfKmQTSvzo+798l6a+ZVbAqRSJU2AHlRucJwbY6uvU0WMZyQWwIab55uBbD+WlIbxm1PBW41D0hSIlTaFCElVtRehPLMcMhBAzd/DIuEGsN2PxrPQJN0+WouextZ9yvgZ7rq08bZP+rirPSoB4KTfQzW1N3utCeN51vUJiTc84BcnZVpp7vDXbQ798R9tl1jZ+R6bW+iT8jwtWx5GpY/Mlel61h7tsy0bFEpPTxzcPg3exIWQsvLg5qN7F5JvkZrNnQpiwbmsdbATUI6VYEoY43JvCV77+L9o8n4UznP06Fb6qhrbgxr7kjyBP3fhHf3c1DKfrZa9L9qIZ6oj1cX42J9HYJygjGoAiYhkaHrkEhecmaQSTBCmZfN5aBVU/y4EcT8X3f3WVdbuBjsrPuBxlWtjvo90jy9PmN1s8T8l6Q+w7BXnS5flZXL5A4sTg0sfCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';