import React from 'react'

//@ts-ignore
import loadable from '@loadable/component'
import {AccountBonusCardTopup} from './pages/bonus-card-topup'
import {MealDeals} from './pages/meal-deals'
import MRTDashboard from './pages/mrt-dashboard'

const fallback = <div />

// Pages
const Home = loadable(() => import('./pages/home'), {fallback})
const Category = loadable(() => import('./pages/category'), {fallback})
const Search = loadable(() => import('./pages/search'), {fallback})
const Product = loadable(() => import('./pages/product'), {fallback})
const MyFavouritesPage = loadable(() => import('./pages/my-favourites'), {fallback})
const BeforeYouGo = loadable(() => import('./pages/before-you-go'), {fallback})
const Account = loadable(() => import('./pages/account'), {fallback})
const Cart = loadable(() => import('./pages/cart'), {fallback})
const CheckoutWrapper = loadable(() => import('./pages/checkout-wrapper'), {fallback})
const CheckoutOrderConfirmation = loadable(() => import('./pages/order-confirmation'), {fallback})
const Login = loadable(() => import('./pages/login'), {fallback})
const Registration = loadable(() => import('./pages/registration'), {fallback})
const Visualization = loadable(() => import('./pages/admin/Visualization'), {fallback})
const Content = loadable(() => import('./pages/content'), {fallback})
const Styleguide = loadable(() => import('./pages/styleguide'), {fallback})
const CmsDevTest = loadable(() => import('./pages/cms-dev-test'), {fallback})
const Error501 = loadable(() => import('./pages/error501'), {fallback})
const BookDelivery = loadable(() => import('./pages/book-delivery'), {fallback})
const CmsStyleGuide = loadable(() => import('./pages/cms-style-guide'), {fallback})
const StoreFinder = loadable(() => import('./pages/store-finder'), {fallback})
const AccountBonusCardTopupConfirmation = loadable(
  () => import('./pages/bonus-card-topup-confirmation'),
  {fallback},
)
const Sitemap = loadable(() => import('./pages/sitemap'), {fallback})
const PageNotFound = loadable(() => import('./pages/page-not-found'), {fallback})

const routes = [
  {path: '/', component: Home, exact: true},
  {path: '/cms-dev-test', component: CmsDevTest, exact: true},
  {path: '/style-guide', component: CmsStyleGuide},
  {path: '/account/register', component: Registration},
  {path: '/account/bonus-card/topup', component: AccountBonusCardTopup, exact: true},
  {path: '/account/bonus-card/topup/confirmation', component: AccountBonusCardTopupConfirmation},
  {path: '/account', component: Account},
  {path: '/p/:productName/:productId.html', component: Product},
  {path: '/p/:productId', component: Product},
  {path: '/search', component: Search},
  {path: '/category/:categoryId', component: Category},
  {path: '/basket', component: Cart},
  {path: '/checkout/summary', component: CheckoutWrapper},
  {path: '/orderconfirmation', component: CheckoutOrderConfirmation},
  {path: '/login', component: Login},
  {path: '/book-delivery', component: BookDelivery},
  {path: '/my-favourites', component: MyFavouritesPage},
  {path: '/Iceland-BeforeYouGoPages', component: BeforeYouGo},
  {path: '/terms.html', component: Content},
  {path: '/store-finder', component: StoreFinder},
  {path: '/basket/addalltobasket', component: Home},
  {path: '/meal-deals', component: MealDeals},
  {path: '/sitemap', component: Sitemap},
  {path: '/page-not-found', component: PageNotFound},

  // Admin routes
  {path: '/admin/visualization', component: Visualization},
  {path: '/admin/preview', component: Home},
  {path: '/styleguide', component: Styleguide},
  {path: '/error501', component: Error501},
  {path: '/mrt-dashboard', component: MRTDashboard},
  {path: '/tsob', component: Home, exact: true},

  // Fallback for all other pages
  {path: '*', component: Content},
]

export default routes
